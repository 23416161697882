<template>
  <div class="protocol_para">
    <h1>《隐私政策》</h1>
    <h2>提示条款</h2>
    <p>
      感谢您选择使用灯影，灯影（“我们”）非常重视您的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过本声明向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。<br />
      本隐私权政策适用于灯影中的产品或服务。<br />
      需要特别说明的是，本隐私权政策不适用于其他第三方向您提供的产品或服务。<br />
      在使用灯影各项功能或服务前，请您务必仔细阅读并透彻理解本隐私权政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用灯影各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过灯影专属客服与我们联系。
    </p>
    <h2>第一部分  定义</h2>
    <p>
      灯影：武汉市灯塔互动文化传播有限公司，公司致力于各领域内的科技创新，运用人工智能及大数据，为客户提供全方位的产品和服务，帮助用户搭建从内容创作-渠道分发-获客管理-用户分析-精准营销-商业变现的全流程服务。包含其移动客户端软件、应用程序。<br />
      灯影服务提供者：武汉市灯塔互动文化传播有限公司。<br />
      个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br />
      个人敏感信息：包括身份证件号码、手机号码、个人生物识别信息、支付宝账号、微信账号、账户信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息等。<br />
      个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
    </p>

    <h2>第二部分  法律声明</h2>
    <p>
      一、权利归属<br />
      除非我们另行声明，灯影所有技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归灯影服务提供者所有。未经灯影服务提供者许可，任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自使用灯影内的任何内容。灯影等文字及/或标识，以及其他标识、徽记、产品和服务名称均为灯影服务提供者在中国的商标，如有宣传、展示等任何使用需要，您必须取得灯影服务提供者事先书面授权。<br />
      二、责任限制<br />
      灯影上的信息（包括但不限于视频上传、元素添加等）由用户自行提供并上传，由用户对其提供并上传的信息承担相应法律责任。灯影服务提供者对此另有约定的，将在相关的协议或其他法律文本中与您进行明确。<br />
      三、知识产权保护<br />
      我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为灯影内容（包括但不限于灯影用户发布的内容）侵犯其合法权益的，可以通过灯影APP的专属邮箱jerrywang@dengtacj.com进行投诉，我们将在收到知识产权权利人合格通知后依据相应的法律法规以及平台规则及时处理。
    </p>

    <h2>第三部分  隐私权政策</h2>
    <p>
      本隐私权政策部分将帮助您了解以下内容： <br />
      1、我们如何收集和使用您的个人信息 <br />
      2、我们如何使用Cookie和同类技术<br />
      3、我们如何共享、转让、公开披露您的个人信息 <br />
      4、我们如何保护您的个人信息 <br />
      5、您如何管理您的个人信息<br />
      6、我们如何处理未成年人的个人信息 <br />
      7、您的个人信息如何在全球范围转移 <br />
      8、本隐私权政策如何更新 <br />
      9、如何联系我们<br />

      一、我们如何收集和使用您的信息 我们会出于本隐私权政策所述的以下目的，收集和使用您的个人信息：<br />
      （一）为您展示和推送视频、第三方商品或服务<br />
      为改善我们的产品或服务、向您提供个性化的服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息的特征，基于特征标签进行间接人群画像并展示、推送信息。<br />
      （二）向您提供产品或服务 1、您向我们提供的信息<br />
      为便于向您提供产品或服务，您可能需要提供联系邮箱、联系电话、支付账号并选择付款方式等。如果我们委托第三方向您提供服务时，我们会在征得您同意后将上述信息共享给第三方。如果您拒绝提供此类信息，我们将无法提供相关产品或服务。<br />
      2、我们在您使用产品或服务过程中收集的信息<br />
      为向您提供更契合您需求的内容展示和搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的产品或服务以及使用方式的信息并将这些信息进行关联，这些信息包括：<br />
      账号信息：注册登陆信息，包括注册的手机号码、昵称、学校、性别、地区等，收集手机号码是履行国家法律法规关于网络实名制（真实身份信息认证）要求的必要信息，如果你拒绝提供手机号码用于注册、登录，我们可能无法为你提供信息发布（包括发视频、评论等）功能。<br />
      设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符的软硬件特征信息）、设备所在位置相关信息（包括IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站的传感器信息）。<br />
      浏览信息：当您使用灯影的产品或服务时，我们会自动收集您对我们产品或服务的详细使用情况，作为有关信息保存。包括您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录。<br />
      请注意，单独的设备信息、浏览信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。<br />
      当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br />
      3、我们通过间接获得方式收集到的您的个人信息<br />
      为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我们的关联公司、合作伙伴会依据法律规定或与您的约定或征得您同意前提下，向我们分享您的个人信息。<br />
      （三）为您提供安全保障<br />
      请注意，为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证、军官证、护照、驾驶证、社保卡、居住证的身份信息完成有关事项的实名认证。
      为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或灯影相关协议规则的情况，我们可能使用或整合您的会员信息、交易信息、设备信息、浏览信息以及我们、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br />
      （四）其他用途<br />
      我们将信息用于本隐私权政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。<br />
      （五）征得授权同意的例外 根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：<br />
      1、与国家安全、国防安全有关的； 2、与公共安全、公共卫生、重大公共利益有关的；<br />
      3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br />
      5、所收集的个人信息是您自行向社会公众公开的；<br />
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道； 7、根据您的要求签订合同所必需的；<br />
      8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />
      9、为合法的新闻报道所必需的；<br />
      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br />
      11、法律法规规定的其他情形。<br />
      如我们停止运营灯影产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以公告的形式通知您，我们并将根据适用法律法规的要求对所持有的个人信息进行删除或匿名化处理。<br />
      <br />
      二、我们如何使用 Cookie  <br />
      为确保产品正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件，依照法律法规的规定，你的个人信息存储于境内。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的账户信息、浏览记录、订单记录、行为记录、内容偏好等数据。<br />
      若您不同意我们在您的计算机或移动设备上存储Cookie的小数据文件，您可卸载灯影不再使用。<br />
      <br />
      三、我们如何共享、转让、公开披露您的个人信息 <br />
      （一）共享<br />
      我们不会与灯影服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br />
      1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br />
      2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br />
      3、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。<br />
      (二）转让 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br />
      2、在灯影服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私权政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。<br />
      （三）公开披露 我们不会主动公开你未自行公开的信息，除非在以下情况下，公开披露您的个人信息：<br />
      1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；<br />
      2、如果我们确定您出现违反法律法规或严重违反灯影相关协议规则的情况，或为保护灯影及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或灯影相关协议规则披露关于您的个人信息，包括相关违规行为以及灯影已对您采取的措施。<br />
      （四）共享、转让、公开披露个人信息时事先征得授权同意的例外<br />
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br />
      1、与国家安全、国防安全有关的；<br />
      2、与公共安全、公共卫生、重大公共利益有关的； <br />
      3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />
      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； <br />
      5、您自行向社会公众公开的个人信息；<br />
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br />
      根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。<br />
      <br />
      四、我们如何保护您的个人信息安全<br />
      （一）我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br />
      （二）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本隐私权政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br />
      （三）互联网并非绝对安全的环境，如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络灯影，以便我们根据您的申请采取相应措施。请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。<br />
      我们将尽力保障您发送给我们的任何信息的安全性。尽最大能力避免我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏。<br />
      （四）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。<br />
      （五）尽管已经采取了上述合理有效措施，并已经遵守了相关法律法规要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。<br />
      五、您如何管理您的个人信息 您可以通过以下方式访问及管理您的个人信息： <br />
      （一）访问您的个人信息<br />
      您有权访问您的个人信息，法律法规规定的例外情况除外。具体访问路径以灯影相应产品或服务内提供的为准。<br />
      （二）更正或补充您的个人信息<br />
      当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。<br />
      （三）删除您的个人信息或用户账户注销<br />
      在以下情形中，您可以向我们提出删除个人信息或用户账户注销的请求：申请注销账号<br />
      1、如果我们处理个人信息的行为违反法律法规； 2、如果我们处理个人信息的行为严重违反了与您的约定；<br />
      3、如果我们永久不再为您提供产品或服务；<br />
      我们决定响应您的删除或注销请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。<br />
      当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br />
      （四）响应您的有关请求<br />
      为保障安全，在您提出某项合法或符合双方约定的事项时（包括访问您的个人信息、更正或补充您的个人信息、删除您的个人信息、用户账户注销、撤回已同意的授权），您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />
      我们将在15个工作日内做出答复。如您不满意，请致电我们（官方客服服务电话：400-073-8838）<br />
      对于您合法合理且符合双方约定的请求，我们原则上不收取费用，但对多次重复、超出合法合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。<br />
      在以下情形中，按照法律法规要求，我们将无法响应您的请求： <br />
      1、与国家安全、国防安全有关的；<br />
      2、与公共安全、公共卫生、重大公共利益有关的； <br />
      3、与犯罪侦查、起诉、审判和执行判决等有关的；<br />
      4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br />
      5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的； <br />
      6、涉及商业秘密的。<br />
      （五）个人信息存储期限<br />
      除本隐私政策另有约定，我们将按法律规定的范围内最短期限存储您的个人信息，若超期存储，您可以向我们个人信息保护专职部门反映情况。<br />
      个人信息的个人操作管理以剧吐产品展示为准，您可以根据实际情况操作管理。 
      <br />
      六、我们如何处理未成年人的个人信息<br />
      如您为未成年人，建议您请您的父母或监护人仔细阅读并同意本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。<br />
      若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。<br />
      对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br />
      <br />
      七、您的个人信息如何在全球范围转移<br />
      我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外： <br />
      1、法律法规有明确规定；<br />
      2、获得您的明确授权； <br />
      3、您通过互联网进行跨境交易等个人主动行为。<br />
      针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。 <br />

      <br />
      八、本隐私权政策如何更新<br />
      我们的隐私权政策可能变更。有关隐私权政策的任何变更，我们会通过灯影公示等方式提前通知您。如您不同意更新后的隐私政策可以选择卸载灯影不再使用。<br />

      <br />
      九、如何联系我们<br />
      我们还设立了个人信息保护专职部门，您可以通过jerrywang@dengtacj.com邮箱与我们联系。一般情况下，我们将在十五个工作日内回复。<br />
      注：本《法律声明及隐私协议》的发布日期为2022年 5 月 8 日，将于2022年 5 月 11 日正式生效。<br />
      附件：《灯影账户注销须知》 灯影账号注销须知内容： 注销本账户后，您将放弃以下权益和资产 <br />
      1.账户信息、会员权益、虚拟资产、账号内容无法恢复 <br />
      2.无法使用账户原验证手机或邮箱登陆灯影服务 <br />
      3.解除与第三方授权或绑定关系<br />
      您理解并同意，灯影无法协助您重新恢复前述服务，您注销账户的行为会给您的售后维权带来诸多不便，请您在操作之前，确保您的账户无任何纠纷，并自行备份灯影账户相关的所有信息和数据，下载并保存好订单和服务的交易凭证、票据（如电子发票）等资料，否则您有可能须支付额外的账户和订单查询费用或无法享受相关售后服务。<br />
      您在申请注销流程中点击同意前，应当认真阅读《灯影账户注销须知》（以下称“《注销须知》”）。<br />
      【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。<br />
      我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销灯影账户后，您的个人信息我们只会在灯影的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关交易记录须在灯影后台保存5年甚至更长的时间。<br />
      1. 如果您仍执意注销账户，您的账户需同时满足以下条件：<br />
      （1）在最近一个月内，账户没有进行更改密码、更改绑定信息等敏感操作；<br />
      （2）自愿放弃账户在灯影系统中的资产和虚拟权益自愿放弃、无欠款； <br />
      （3）账户内无未完成的订单和服务；<br />
      （4）账户无任何纠纷，包括投诉举报或被投诉举报；<br />
      （5）账户对应的灯影支付账户已注销，且已妥善处理了灯影支付账户下的所有问题；<br />
      （6）账户已经解除与其他网站、其他APP的授权登录或绑定关系。 <br />
      2. 灯影账户一旦被注销将不可恢复，请您在操作之前自行备份灯影账户相关的所有信息和数据。请您保存好服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。<br />
      3. 如果您的灯影账户同时是灯影的绑定账户名，您需先解除相关绑定； <br />
      4. 在灯影账户注销期间，如果您的灯影账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，灯影有权自行终止本灯影账户的注销而无需另行得到您的同意。<br />
      5. 注销灯影账户，您将无法再使用本灯影账户，也将无法找回您灯影账户中及与账户相关的任何内容或信息，包括但不限于：<br />
      （1）您将无法登录、使用本灯影账户；<br />
      （2）本灯影账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；<br />
      （3）您通过灯影账户使用、授权登录或绑定灯影账户后使用的灯影相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的权益视为您自行放弃，将无法继续使用。您理解并同意，灯影无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息，具体可与我们的客服联系。<br />
      6. 注销本灯影账户并不代表本灯影账户注销前的账户行为和相关责任得到豁免或减轻。
    </p>

  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  created() {},
  methods: {},
  async mounted() {},
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
